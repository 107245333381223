<template>
  <static-fullscreen-card>
    <portal to="v-goup" v-if="idShow">
      <v-btn class="mr-3" fab small @click="$emit('close', data)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </portal>
    <template v-slot:title> ({{ data.id }}) {{ m.title + ` "${data.name}"` }} </template>
    <template v-slot:actions>
      <a-btn-edit v-if="accessEdit" @click="showEditDialog = true" :disabled="!canEdit" />
      <a-btn-delete
        v-if="id && data.status === 0 && getAccess(`${accessName}.delete`, { users: [data.createdby_id] })"
        :disabled="!canDelete"
        @click="removeDialogShow = true"
      />
    </template>
    <v-row>
      <v-col cols="12" sm="6" class="px-2">
        <material-card color="secondary">
          <template v-slot:heading> </template>
          <a-form-view :value="data" :model="modelLeft" :config="{ dense: true, hideNull: false }" @click="onClick($event)" />
        </material-card>
      </v-col>
      <v-col cols="12" sm="6" class="px-2">
        <material-card color="secondary">
          <template v-slot:heading> </template>
          <a-form-view :value="data" :model="modelRight" :config="{ dense: true, hideNull: false }" :style1="{ height: blockHeight + 'px' }" />
        </material-card>
      </v-col>
      <v-col cols="12" sm="12" class="px-2 pt-1">
        <material-card color="third" class="block_detail">
          <template v-slot:heading>
            <v-tabs v-model="tab" background-color="transparent" slider-color="white">
              <v-tab v-for="(tab, i) in tabs" v-if="tab.show" :key="i" class="mr-3" :href="`#tab-${tab.name}`">
                {{ tab.title }}
              </v-tab>
            </v-tabs>
          </template>
          <v-tabs-items v-model="tab" class="transparent" style="min-height: 200px" touchless>
            <v-tab-item v-for="(t, i) in tabs" :key="i" v-if="t.show" :id="`tab-${t.name}`">
              <v-card-text v-if="t.name == 'files'" :style="styleDetailCard" class="pa-0">
                <v-fab-transition v-if="true">
                  <v-btn fab dark color="green" absolute bottom small style="bottom: 25px" left class="v-btn--example" @click="addNewDocs('docs')">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-fab-transition>
                <a-view-filesdata :value="data.filesData" />
              </v-card-text>
              <a-view-images v-if="t.name == 'photos'" :value="data.photos" :style="styleDetailCard" />
              <v-card-text v-if="t.name == 'equipment'" :style="styleDetailCard" class="pa-0">
                <a-table-f-api
                  ref="tableEquipment"
                  api="/accounting/dir/equipment"
                  :model="[
                    { name: 'code', title: 'Инв. номер', type: 'string', sortable: true, width: 120 },
                    { name: 'name', title: 'Наименование', type: 'string', sortable: true, width: 300 },
                    { name: 'value', title: 'Стоимость', type: 'number', sortable: true, width: 80 },
                  ]"
                  :searchable="false"
                  :defaults="{
                    filters: { employee_id: id },
                    sort: { key: 'id', order: 'DESC' },
                    paramName: 'equipment',
                  }"
                >
                </a-table-f-api>
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </material-card>
      </v-col>
    </v-row>
    <div v-if="loading" class="loading-block">
      <div class="loader"></div>
    </div>
    <portal to="v-main">
      <dir-dialog v-if="showEditDialog" v-model="showEditDialog" :id="id" :api="dirCurrent.api" :dir="dirCurrent" />
      <s-quick-addDocument v-if="showAddDocDialog" v-model="showAddDocDialog" :id="id" :api="url" :modelDoc="addDocModel" />
      <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" :title="removeTitle" />
    </portal>
    <confirm-dialog ref="confirmDialog" />
    <a-loader v-if="loading" />
  </static-fullscreen-card>
</template>

<script>
import { autoHeightBlock, getAccess, popupMenu, removeEl, genModel } from "@/components/mixings";
export default {
  mixins: [autoHeightBlock, getAccess, removeEl, genModel],
  props: {
    idShow: Number,
    dirCurrent: Object,
    m: Object,
  },
  components: {
    dirDialog: () => import("./../dialogs/dirDialog"),
  },
  data() {
    return {
      id: 0,
      idEdit: 0,
      idDeleting: 0,
      fieldsRO: [],
      suEditShow: false,
      showEditDialog: false,
      removeDialogShow: false,
      removeTitle: "Вы действительно хотите запись'?",
      showAddDocDialog: false,
      addDocModel: null,
      loading: false,
      loaded: false,
      title: "",
      data: {},
      tab: 0,
      tabList: [
        { name: "files", title: "Документы", show: true },
        { name: "equipment", title: "Оборудование", show: true },
      ],

      statusCur: null,
    };
  },
  created() {
    this.loading = true;

    this.$root.title = this.m.title;
    this.id = this.idShow || Number(this.$route.params.id);
    //    this.permit = this.getAccess("menu.objects");
    this.fitchData();
    this.loaded = true;
  },
  mounted() {},

  computed: {
    tabs() {
      let res = [];
      if (!this.m.tabList) res = this.tabList;
      else {
        res = this.tabList.filter((t) => this.m.tabList.split(",").includes(t.name));
      }
      return res;
    },
    api() {
      return this.dirCurrent.api;
    },
    url() {
      return this.dirCurrent.api;
    },
    accessName() {
      return this.m.accessName || "none";
    },
    accessEdit() {
      return this.getAccess(this.accessName + ".edit", { users: [this.data.createdby_id] });
    },

    canEdit() {
      return true;
    },
    canDelete() {
      return true;
    },
    modelLeft() {
      let model = this.calcModel("viewForm1");
      return model;
    },
    modelRight() {
      let model = this.calcModel("viewForm2");
      return model;
    },
    model: {
      get() {
        let conf = JSON.parse(JSON.stringify(this.m?.config || {}));
        const f = conf.configField;
        let config;
        if (f) {
          config = conf.config.find((el) => {
            if (Array.isArray(el.value)) return el.value.includes(this.data[f]);
            return el.value == this.data[f];
          });
        }
        config = config || conf.default;
        // this.configHead = config;
        let model = this.calcModelConfig(config);
        return model;
      },
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.fitchData();
      }
    },

    showAddDocDialog(v) {
      if (!v) {
        this.fitchData();
      }
    },
  },
  methods: {
    onClick() {},
    addNewDocs(field) {
      this.addDocModel = this.model.find((el) => el.name == field);
      if (!this.addDocModel) return;
      this.showAddDocDialog = true;
    },
    async fitchData() {
      this.loading = true;
      const d = await this.$axios.get(this.url + "/" + this.id);
      if (d.data && d.data.data) {
        this.data = d.data.data;
      }
      this.afterFetchData();
      this.loading = false;
    },
    afterRemove(data) {
      this.removeDialogShow = false;
      if (this.idShow) this.$emit("close", this.data);
      else this.$router.push({ name: "videos" });
    },
    afterFetchData(r) {
      if (this.id) {
        //   this.calcModel();
      }
      this.loaded = true;
    },
  },
};
</script>
